<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Assessment Timeline</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="datatable"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Employee Name</th>
                    <th>Module</th>
                    <th>Trainning Date</th>
                    <th>Assessor Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(data, index) in assessment_timeline" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ data.enrolled_user.name }}</td>
                    <!-- <template v-for="(attendance, i) in data.monthly_plan.attendance_for_normal" :key="i">
                                      <td>{{ ++index }}</td>
                                      <template v-for="(enroll, j) in data.monthly_plan.enroll_lists" :key="j">
                                        <td v-if="attendance.employee_id == enroll.enrolled_user_id">{{ attendance.user.name }}</td>
                                      </template> -->
                    <td>{{ data.monthly_plan.module.name }}</td>
                    <td>{{ data.monthly_plan.from_date }}</td>
                    <td>{{ data.assessor.name }}</td>
                    <td>{{ data.status ? "Confrimed" : "Waiting" }}</td>
                    <td>
                      <span>
                        <router-link
                          class="action-icon"
                          :to="{
                            name: 'assessment-timeline/edit',
                            params: { id: data.id },
                          }"
                        >
                          <i class="mdi mdi-square-edit-outline"></i>
                        </router-link>
                      </span>
                    </td>
                    <!-- </template> -->

                    <!-- <template>
                                        <div v-for="(attendance, i) in data.monthly_plan.attendance_for_normal" :key="i">
                                          <td>{{ index }}</td>
                                          <td>{{ attendance.user.name }}</td>
                                        
                                        </div>
                                      </template> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Timeline
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      assessment_timeline: [],
      status: "",
      arr_status: [{ name: "Active" }, { name: "Inactive" }],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAssessmentTimeline() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/after-assessment-before-week`)
        .then((response) => {
          // console.log('respone=>',response.data.data);
          this.assessment_timeline = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#datatable").DataTable();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAssessmentTimeline();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
